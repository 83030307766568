import Cookies from 'js-cookie';
import { types } from './sdkLoader';
import LocaleService from '../services/LocaleService';

const { LatLng } = types;

export const LOCALE = {
  BRAZIL: 'br',
  POLAND: 'pl',
  SOUTH_AFRICA: 'za',
};

export const LOCALES = Object.values(LOCALE);

export const LATLNG_FOR_LOCALE = {
  [LOCALE.POLAND]: new LatLng(52.1246099075455, 19.30063630556),
  [LOCALE.BRAZIL]: new LatLng(-9.58890301712257, -51.6197890205486),
  [LOCALE.SOUTH_AFRICA]: new LatLng(-28.3782721906973, 23.9137106762068),
};

// Name and arg should be updated as its from window now
export const getCurrentLocale = () => {
  return LocaleService.locale;
};

export const getLocaleFromHost = () => {
  if (typeof window === 'undefined') {
    console.error('Called getLocaleFromHost on server side!!!');
    return null;
  }
  const locale = window.location.hostname.slice(0, 2);

  if (LOCALES.includes(locale)) {
    return locale;
  }

  return null;
};

/**
 * @returns [[west, south],[east, north]]
 */
export const getBoundingBoxForLocale = url => {
  const locale = getCurrentLocale(url);

  switch (locale) {
    case LOCALE.POLAND:
      return [
        [14.1229707, 49.0020468],
        [24.145783, 55.0336963],
      ];

    case LOCALE.BRAZIL:
      return [
        [-73.9830625, -33.7683777809],
        [-33.8689056, 5.2842873],
      ];

    case LOCALE.SOUTH_AFRICA:
      return [
        [16.13, -35.43],
        [33.19, -21.69],
      ];

    default:
      break;
  }

  return undefined;
};

export const getContactEmailForLocale = locale => {
  const localeCode = locale || getCurrentLocale();

  switch (localeCode) {
    case LOCALE.BRAZIL:
      return 'posvendas@husqvarnagroup.com';

    case LOCALE.POLAND:
      break;

    case LOCALE.SOUTH_AFRICA:
      break;

    default:
      break;
  }

  return 'husqvarna@email.com';
};

export const LANGUAGE_COOKIE_KEY = 'interfaceLang';

export const LANGUAGE = {
  ENGLISH: 'en',
  POLISH: 'pl',
  BRAZIL_PORTUGUESE: 'pt',
};

export const LANGUAGES = Object.values(LANGUAGE);

export const LANGUAGES_BY_LOCALE = {
  [LOCALE.SOUTH_AFRICA]: [LANGUAGE.ENGLISH],
  [LOCALE.BRAZIL]: [LANGUAGE.BRAZIL_PORTUGUESE, LANGUAGE.ENGLISH],
  [LOCALE.POLAND]: [LANGUAGE.POLISH, LANGUAGE.ENGLISH],
};

export const getLangFromUrl = url => {
  if (!url && typeof window === 'undefined') return getDefaultLang(url);

  const locationFinal = url || window.location.pathname;

  const fromUrl = LANGUAGES.find(lang => locationFinal.startsWith('/' + lang));

  return fromUrl || getDefaultLang(url);
};

export const getDefaultLang = url => {
  const cookieLang = Cookies.get(LANGUAGE_COOKIE_KEY);

  if (cookieLang) return cookieLang;

  const locale = getCurrentLocale(url);

  return LANGUAGES_BY_LOCALE[locale]?.[0] || LANGUAGE.ENGLISH;
};

export const getLabelForLang = lang => {
  switch (lang) {
    case LANGUAGE.ENGLISH:
      return 'English';

    case LANGUAGE.BRAZIL_PORTUGUESE:
      return 'Português do Brasil';

    case LANGUAGE.POLISH:
      return 'Polski';

    default:
      return 'English';
  }
};

// The language is prepended to every url path
export const LANG_URL_PARAM = `:lang(${LANGUAGES.join('|')})?`;
